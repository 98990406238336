import { Typography } from "@mui/material";
import React from "react";

const lessons = {
  1: '09.00 - 10.30',
  2: '10.40 - 12.10',
  3: '12.50 - 14.20',
  4: '14.30 - 16.00',
  5: '16.10 - 17.40',
  6: '17.50 - 19.20',
  7: '19.30 - 21.00'
}
const TimeTitle = (props) => {
  return (
    <Typography
      variant="h5"
      sx={{
        color: "#51A4FF",
      }}
    >
      {lessons[props.lesson]} (Мск) <br/>
      {props.lesson}-я пара
    </Typography>
  );
};

export default TimeTitle;
