import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const DayCardDate = (props) => {

const [update, setUpdate] = useState(false)

const [date, setDate] = useState([])

  const days = [
    '',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ]

  useEffect(()=>{
    setUpdate(true)
    if(update){
      let customDate = props.controlDate
      new Date(customDate.setDate(customDate.getDate()))
      setDate({
        "date": customDate.getDate(),
        "month": customDate.getMonth()
      })
    }
  },[update])

  return (
    <Stack>
      <Typography variant="h5">{days[props.day]}</Typography>
    </Stack>
  );
};
