import * as React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import ControlTitle from "./ControlTitle";
import ControlButtons from "./ControlButtons";
import ControlDivider from "./ControlDivider";
import AutoCompleteControl from "./AutoCompleteControl";

const Control = (props) => {
  return (
    <Stack direction="column">
      <Stack
        sx={{
          maxWidth: "330px",
          minHeight: "357px",
          borderRadius: "10px",
          border: "20px #F0F0F0 solid",
          flexDirection: "column",
          padding: "20px",
          marginTop: "7rem",
          "@media(max-width:950px)": {
            marginBottom: "20px",
            height: "315px",
            marginTop: "7rem",
          },
        }}
      >
        <ControlTitle
          weekNum={props.weekNum}
          controlDate={props.controlDate}
          controlDateTo={props.controlDateTo}
          handleSetControlDateTo={props.handleSetControlDateTo}
        />
        <ControlButtons
          setWeekNum={props.setWeekNum}
          weekNum={props.weekNum}
          handleWeekNumMinus={props.handleWeekNumMinus}
          handleWeekNumPlus={props.handleWeekNumPlus}
          handleSetControlDate={props.handleSetControlDate}
          controlDate={props.controlDate}
          controlDateTo={props.controlDateTo}
          handleSetControlDateTo={props.handleSetControlDateTo}
          handleSetGroupData={props.handleSetGroupData}
          handleSetLessonData={props.handleSetLessonData}
          handleSetTeacherData={props.handleSetTeacherData}
        />
        <ControlDivider />

        <AutoCompleteControl placeholder="Выберите группу" data={props.groups} handleSetData={props.handleSetGroupData}/>
        <AutoCompleteControl placeholder="Выберите предмет" data={props.lessons} handleSetData={props.handleSetLessonData}/>
        <AutoCompleteControl placeholder="Выберите преподавателя" data={props.teachers} handleSetData={props.handleSetTeacherData}/>

        {/* <ControlLesson
          lessons={props.lessons}
          handleSetLessonData={props.handleSetLessonData}
        />
        <ControlTeacher
          teachers={props.teachers}
          handleSetTeacherData={props.handleSetTeacherData}
        /> */}
      </Stack>
      <Stack
        sx={{
          gap: "6px",
          maxWidth: "330px",
          marginBottom: "6px",
        }}
      >
        <Typography variant="h3">
          {" "}
          <span
            style={{
              fontSize: "20px",
              color: "#51A4FF",
            }}
          >
            Нужна помощь?
          </span>
        </Typography>
        <Stack
          direction="column"
          sx={{
            borderBottom: "1px  #E5E5E5 solid",
          }}
        >
          <Typography variant="h6">
            Факультет управления территориями
          </Typography>
          <Typography variant="h6">
            {" "}
            <span
              style={{
                fontSize: "20px",
                color: "#51A4FF",
              }}
            >
              feut@feut.ru
            </span>
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            borderBottom: "1px  #E5E5E5 solid",
          }}
        >
          <Typography variant="h6">Геодезический факультет</Typography>
          <Typography variant="h6">
            {" "}
            <span
              style={{
                fontSize: "20px",
                color: "#51A4FF",
              }}
            >
              geofak@miigaik.ru
            </span>
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            borderBottom: "1px  #E5E5E5 solid",
          }}
        >
          <Typography variant="h6">Картографический факультет</Typography>
          <Typography variant="h6">
            {" "}
            <span
              style={{
                fontSize: "20px",
                color: "#51A4FF",
              }}
            >
              kartfak@miigaik.ru
            </span>
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            borderBottom: "1px  #E5E5E5 solid",
          }}
        >
          <Typography variant="h6">
            Факультет геоинформатики и информационной безопасности
          </Typography>
          <Typography variant="h6">
            {" "}
            <span
              style={{
                fontSize: "20px",
                color: "#51A4FF",
              }}
            >
              gis_faculty@miigaik.ru
            </span>
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            borderBottom: "1px  #E5E5E5 solid",
          }}
        >
          <Typography variant="h6">По техническим вопросам</Typography>
          <Typography variant="h6">
            {" "}
            <span
              style={{
                fontSize: "20px",
                color: "#51A4FF",
              }}
            >
              support@edu.miigaik.ru
            </span>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Control;
