import React from 'react';
import { Box } from '@mui/material';

const ControlDivider = () => {
  return (
   <Box sx={{
    width: '100%',
    height: '2px',
    background:' #F0F0F0',
    borderRadius: '20px',
    marginTop:'16px'
   }}/>
  )
}

export default ControlDivider