import React from 'react'
import { Stack } from '@mui/system'
import LessonCard from './LessonCard'
import { DayCardDate } from './DayCardDate'


const DayCard = (props) => {
  return ( 
  <>
  <DayCardDate  index={props.index} controlDate={props.controlDate} day={props.day}/>
   <Stack sx={{
    borderRadius:'20px',
    border:'20px #F0F0F0 solid',
    marginBottom:'20px'
   }}>
   
    {props.lessons?.map((ele)=>{
      return(
        <LessonCard subject={ele.subject.name} teacher={ele.teacher} lesson={ele.lesson} data={ele} />
      )
    }
    )}
   </Stack>
   </>
  )
}

export default DayCard