import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ControlButtons = (props) => {

const date = props.controlDate;
const dateTo = props.controlDateTo;

  return (
    <Stack sx={{
        flexDirection:'row',
        marginTop:'20px',
        gap:'16px'
    }}>
        <Button sx={{
            width:'30px',
            height:'40px',
            color:'black',
            borderRadius:'10px',
            border: '1px solid #000000'

        }}
        onClick={()=>{
            props.setWeekNum(props.weekNum - 1)
            props.handleSetControlDate(date.setDate(date.getDate() - 7))
            props.handleSetControlDate(date)
            props.handleSetControlDateTo(dateTo.setDate(dateTo.getDate() - 7))
            props.handleSetControlDateTo(dateTo)
        }}
        >
            <ArrowBackIosIcon/>
        </Button>
        <Button
        sx={{
            width:'40px',
            height:'40px',
            color:'black',
            borderRadius:'10px',
            border: '1px solid #000000'
        }}
        onClick={()=>{
            props.setWeekNum(props.weekNum + 1)
            props.handleSetControlDate(date.setDate(date.getDate() + 7))
            props.handleSetControlDate(date)
            props.handleSetControlDateTo(dateTo.setDate(dateTo.getDate() + 7))
            props.handleSetControlDateTo(dateTo)
        }}
        >
            <ArrowForwardIosIcon/>
        </Button>
        
    </Stack>
  )
}

export default ControlButtons