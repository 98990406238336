import { Button } from '@mui/material'
import React from 'react'

const ConnectBtn = (props) => {
  let url = '';
  if(props.data.teacher.subjects.length > 0){
    url = props.data.teacher.subjects.find(subject => subject.subject == props.data.subject.id)?.link
  }
  return (
    <Button sx={{
        width:'180px',
        height:'40px',
        border:'1px solid #000000',
        borderRadius:'10px'
    }}
    onClick={()=>window.location.href = url}
    >Ссылка</Button>
  )
}

export default ConnectBtn;