import React from 'react'
import { Typography } from '@mui/material'

export const LessonTitle = (props) => {
  return (
    <Typography variant="h5" sx={{
      width:'200px',
    }}
    >{props.subject}</Typography>
  )
}
