import { Typography } from '@mui/material'
import React from 'react'

const TeacherTitle = (props) => {
  return (
    <Typography variant="h6" sx={{
      color:'#A0A0A0',
      ["@media(max-width:950px)"]:{
        width:'200px'
     }
    }}>
       {props.teacher.last_name} {props.teacher.first_name} {props.teacher.middle_name}
    </Typography>
  )
}

export default TeacherTitle