import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Content from "./components/Content/Content";
import Error from "./components/Error/Error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error/>}></Route>
        <Route path="" element={<Content/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
