import React from 'react';
import { Stack, Typography } from '@mui/material';

const Header = () => {
  return (
    <Stack sx={{
        width:'100%',
        height:'5rem',
        marginBottom:'0rem',
       
    }}>
     <Stack>
     <Typography sx={{
      fontSize:'45px',
      borderBottom: '1px  #E5E5E5 solid',
      fontWeight:'900',
      marginBottom:'2rem'
     }}
     onClick={() => (window.location.href = "https://miigaik.ru/")}> 
     <span style={{
        color:'#51A4FF',
    }}>МИИГАиК</span>
     </Typography>
      </Stack> 
     <Typography variant="h4">
     <span style={{
        color:'#51A4FF',
    }}>Расписание</span><br/>
     онлайн занятий<br/> 
     </Typography>
    </Stack>
  )
}

export default Header