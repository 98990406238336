import { Typography, Stack } from '@mui/material'
import React from 'react';
import Iframe from 'react-iframe'

const ControlTitle = (props) => {

  const weeks = ['нижняя', 'верхняя']
  
  return (
    <>
     <Stack direction="row">
     <Iframe url="https://yandex.com/time/widget/?geoid=213&lang=ru&type=digital"
        frameBorder="0"
        width="130px"
        height="30px">
      </Iframe>
      <Typography variant='h5'>(Мск)</Typography>
     </Stack>
        
      <Typography variant='h6'>
      Неделя: <span style={{
          fontSize:'20px',
          color:'#51A4FF',
      }}>{weeks[props.weekNum % 2]}</span></Typography>
      <Typography>{props.controlDate.getDate()}.{props.controlDate.getMonth()+1}.{1900 + props.controlDate.getYear()} - {props.controlDateTo.getDate()}.{props.controlDateTo.getMonth()+1}.{1900 + props.controlDateTo.getYear()}</Typography>
    </>
    
  )
}

export default ControlTitle
