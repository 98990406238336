import { Stack } from "@mui/system";
import React from "react";
import logo from "../../images/logo.png";
import { Typography, Button } from "@mui/material";

const Footer = () => {
  return (
    <Stack
      sx={{
        paddingTop:'8px',
        "@media(min-width:1200px)": {
          maxWidth: "1620px",
        },
        flexDirection:'row',
        "@media(max-width:950px)": {
            flexDirection: "column",
          },
        borderTop: "1px  #E5E5E5 solid",
        justifyContent: "space-between",
      }}
      
    >
      <Stack
        direction="row"
        sx={{
          gap: "22px",
          alignItems: "center",
        }}
      >
        <img
          alt="логоУи"
          style={{
            width: "100px",
          }}
          src={logo}
        />
        <Typography variant="h6">
          Разработано <br />
          Управлением <br />
          Информатизации <br />
        </Typography>
      </Stack>
      <Stack direction="row" sx={{
        gap:'20px',
        alignItems:'center'
      }}>
        <Button
          sx={{
            width: "180px",
            height: "40px",
            border: "1px solid #000000",
            borderRadius: "10px",
            color: 'black'
          }}
          onClick={() => (window.location.href = "https://miigaik.ru/")}
        >
          На главный сайт
        </Button>
        <Button
          sx={{
            width: "180px",
            height: "40px",
            border: "1px solid #000000",
            borderRadius: "10px",
            color: 'black'
          }}
          onClick={() => (window.location.href = "https://sdo.miigaik.ru/")}
        >
          Перейти в СДО
        </Button>
      </Stack>
      <Stack sx={{
         alignSelf:'center'
      }}>
        <Typography variant='h6'> ©МИИГАИК, 2023</Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
