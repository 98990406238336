import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

const HelloModal = () => {
  return (
   <Stack sx={{
    alignSelf:'center',
    justifySelf:'center'
   }}>
    <Typography variant="h5">Для просмотра расписания <br/>
    <span style={{
          color:'#51A4FF',
      }}>Выберите группу, предмет или преподавателя</span>
    </Typography>
   </Stack>
  )
}

export default HelloModal