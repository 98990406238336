import { Stack } from '@mui/system'
import React from 'react'
import DayCard from './DayCard'
import { useState } from 'react'

const Schedule = (props) => {

const newData = []

if(props.data !== undefined){}
props.data?.map((ele)=>{
      newData[ele.day] = []
});
props.data?.map((ele)=>{
      newData[ele.day].push(ele) 
})
return (
      <Stack sx={{
      flexDirection:'column'
    }}>
      {newData?.map((ele, index)=>{
        
          return(
            <DayCard index={Number(index)} controlDate={props.controlDate} day={ele[0].day} lessons={ele} data={ele}/>
        )
      })}
     
    </Stack>
  )
}

export default Schedule