import React from 'react'
import { Stack } from '@mui/material'
import TeacherTitle from './TeacherTitle'
import { LessonTitle } from './LessonTitle'
import ConnectBtn from './ConnectBtn'
import { FormLessonTitle } from './FormLessonTitle'

function ContentLesson(props) {
  return (
    <Stack
    sx={{
      width:'400px',
      flexDirection: "column",
      marginRight:'15rem',
      marginTop:'20px',
      gap:'18px',
      marginBottom:'20px',
      "@media(max-width:1200px)":{
        marginLeft:'3rem',
        marginRight:'5rem'
      },
      "@media(max-width:950px)":{
       marginLeft:'0',
        marginRight:'0',
        textAlign:'center',
        alignItems:'center'
    }
    }}
  >
    
      <LessonTitle subject={props.subject}/>
      <FormLessonTitle form={props.data}/>
      <TeacherTitle teacher={props.teacher}/>
      <ConnectBtn data={props.data}/>

      
  </Stack>
  )
}

export default ContentLesson