
import React from 'react'
import TimeTitle from './TimeTitle'
import {Stack} from '@mui/material'
import { GroupTitle } from './GroupTitle'

export const TimeGroupBlock = (props) => {
  return (
    <Stack sx={{
        width:'250px',
        direction:'column',
        marginTop:'20px',
        marginLeft:'20px',
        marginBottom:'20px',
        gap:'18px',
        justifyContent:'space-between',
        "@media(max-width:1200px)":{
           textAlign:'center'
          }
    }}>
        <TimeTitle lesson={props.lesson}/>
        <GroupTitle group={props.group}/>
    </Stack>
  )
}
