import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import Control from "../Control/Control";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Schedule from "../Schedule/Schedule";
import HelloModal from "./HelloModal";

const Content = () => {
  // Данные из селектов для запроса
  const [groupData, setGroupData] = useState(0);
  const [lessonData, setLessonData] = useState(0);
  const [teacherData, setTeacherData] = useState(0);

  // Данные для подтягивания в расписание
  const [teachers, setTeachers] = useState();
  const [groups, setGroups] = useState();
  const [lessons, setLessons] = useState();

  // Все что мы получаем с сервера
  const [data, setData] = useState(null);

  // Кнопки выбора недели
  const handleWeekNumPlus = (value) => setWeekNum(value + 1);
  const handleWeekNumMinus = (value) => setWeekNum(value - 1);
  const [controlDate, setControlDate] = useState(new Date())
  const [controlDateTo, setControlDateTo] = useState(new Date())
  const handleSetControlDate = (value) => setControlDate(value);
  const handleSetControlDateTo = (value) => setControlDateTo(value);
  const [weekNum, setWeekNum] = useState(0);
  // Поход на селекты
  const handleSetGroupData = (value) => setGroupData(value);
  const handleSetLessonData = (value) => setLessonData(value);
  const handleSetTeacherData = (value) => setTeacherData(value);

  const [update, setUpdate] = useState(false)
  const [update2, setUpdate2] = useState(false)

  const numWeekGet = (date) => {
    let currentDate = new Date(date);
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    setWeekNum(weekNumber)
  }

  useEffect(()=>{
    setUpdate(true)
    if(update){
      new Date(controlDateTo.setDate(controlDateTo.getDate() + 6))
      new Date(controlDate.setDate(controlDate.getDate() - controlDate.getDay() + 1))
      controlDateTo.getDay() === 6 ? new Date(controlDateTo.setDate(controlDateTo.getDate() + 7 - controlDateTo.getDay())) : new Date(controlDateTo.setDate(controlDateTo.getDate() - controlDateTo.getDay()))
    }
  },[update])

  useEffect(()=>{
    let currentDate = new Date();
    let startDate = new Date(currentDate);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    
    setWeekNum(weekNumber)
  },[])

  useEffect(()=>{
    fetchData('/api/student_group/').then((data)=>setGroups(data))
    fetchData('/api/subject/').then((data)=>setLessons(data))
    fetchData('/api/teacher/').then((data)=>setTeachers(data))
  },[])

  useEffect(() => {
    numWeekGet(controlDate)
  }, [weekNum])

  useEffect(() => {
    console.log(controlDate)
  },[weekNum])

  useEffect(()=>{
    setData(undefined)

    let url = `/api/schedule/?weeks=${Number(weekNum)}&year=${controlDate.getFullYear()}`

    if(groupData){
      url += `&group=${groupData}`
    }

    if(lessonData){
      url += `&subject=${lessonData}`
    }

    if(teacherData){
      url += `&teacher=${teacherData}`
    }

    if(!groupData && !lessonData &&  !teacherData){
      setData(null)
    }

    if(groupData || lessonData || teacherData)
    {
    
      fetchData(url)
        .then((data)=>setData(data));
    }
  }, [groupData, lessonData, teacherData, weekNum])

  const fetchData = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };


  return (
    <Box>
      <Container
        sx={{
          "@media(min-width:1200px)": {
            maxWidth: "1620px",
          },
          minHeight:'90vh'
        }}
      >  
       <Header />
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap:'5rem',
            "@media(max-width:950px)": {
              flexDirection: "column",
            },
            minHeight:'80vh'
          }}
        >
          <Control
            teachers={teachers}
            lessons={lessons}
            groups={groups}
            handleSetGroupData={handleSetGroupData}
            handleSetLessonData={handleSetLessonData}
            handleSetTeacherData={handleSetTeacherData}
            weekNum={Number(weekNum)}
            setWeekNum={setWeekNum}
            handleWeekNumMinus={handleWeekNumMinus}
            handleWeekNumPlus={handleWeekNumPlus}
            handleSetControlDate={handleSetControlDate}
            handleSetControlDateTo={handleSetControlDateTo}
            controlDateTo={controlDateTo} 
            controlDate={controlDate}
          />
          {
            data ?    <Schedule  controlDate={controlDate} data={data} /> : <HelloModal/> 
          }
        
        </Stack>
        <Footer/>

      </Container>
    </Box>
  );
};

export default Content;
