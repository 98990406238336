import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const AutoCompleteControl = (props) => {
  return (
    <Autocomplete
      disableClearable={true}
      onChange={(e) => {
        props.handleSetData(
          props.data[
            props.data.map((item) => item.name).indexOf(e.target.innerHTML)
          ].id
        );
      }}
      options={props.data?.map((item) => item.name)}
      sx={{ width: 250, marginBottom:2 }}
      renderInput={(params) => (
        <TextField {...params} label={props.placeholder}  />
      )}
    />
  );
};

export default AutoCompleteControl;
