import { Typography } from "@mui/material";
import React from "react";

export const GroupTitle = (props) => {
  return (
    <Typography variant="h6" sx={{
      color:'#A0A0A0'
    }}>
       {props.group}
    </Typography>
  )
}
