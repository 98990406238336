import { Stack } from "@mui/system";
import React from "react";
import { TimeGroupBlock } from "./TimeGroupBlock";
import  ContentLesson  from "./ContentLesson";

const LessonCard = (props) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        ["@media(max-width:950px)"]:{
            flexDirection:'column',
            alignItems:'center'
        },
        borderBottom:"10px #F0F0F0 solid",
        '&:last-child':{
          borderBottom:"none",
        }
      }}
    >
      <TimeGroupBlock lesson={props.lesson} group={props.data.group.name}/>
      <ContentLesson subject={props.subject} teacher={props.teacher} link={props.subject.teacher_subject} data={props.data}/>
{/*  */}

    </Stack>
  );
};

export default LessonCard;

