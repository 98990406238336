import React from "react";
import { Typography } from "@mui/material";

export const FormLessonTitle = (props) => {
  const forms = [
   'Лекция','Практика'
  ]
  return <Typography variant="h6" sx={{
    color:'#A0A0A0'
  }}>{forms[props.form.subject.subject_type]}</Typography>;
};
