import React from "react";
import { Box } from "@mui/material";
import StyleText from "./ErrorText.css";

export default function Error() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        fontSize:'10vh',
        fontFaminly:'Roboto'
      }}
    >
  
        <Box class="hero glitch layers" data-text="404">
          <span>404</span>
        </Box>
     
      <Box
        class="hero glitch layers"
    
        data-text="Страница не найдена :c"
      >
        <span>Страница не найдена :c</span>
      </Box>
    </Box>
  );
}
